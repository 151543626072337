import classes from './ErrorPage.module.css'
import React, { useContext } from 'react';
import GlobalStore from '../../contexts/GlobalStore';
import { useIntl, FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom';


function ErrorPage(props) {
    const store = useContext(GlobalStore)
    const intl = useIntl()
    const params = useParams()
    return (
        <div className={classes.main}>
            <div className={classes.body}>
                <h1>
                    404
                </h1>
                <div className='app-btn-anchor-1'>
                    <Link to={`/${params.lang}`}>Back to Home</Link>
                </div>
            </div>
        </div>
    )
}


export default ErrorPage;