import classes from './TheFooter.module.css'
import React, { Fragment, useContext, useEffect, useRef } from 'react';
import GlobalStore from '../../contexts/GlobalStore';

function TheFooter(props) {
    const store = useContext(GlobalStore)
    const publicConfigFromServerRef = store.publicConfigFromServerRef
    const year = new Date(publicConfigFromServerRef.current.date).getFullYear()
    return (
        <div className={classes.main}>
            <h2 className={classes.logo}>
                Steven J.
            </h2>
            <div className={classes.copy}>
                <p>
                    &copy; {year} &nbsp; Steven J. Wang &nbsp; &nbsp; All Rights Reserved
                </p>
            </div>
        </div>
    )
}

export default TheFooter;