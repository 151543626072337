import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import TheFooter from '../../components/TheFooter/TheFooter';
import TheHeader from '../../components/TheHeader/TheHeader';
import classes from './LoginPage.module.css'


function LoginPage(props) {
    const navigate = useNavigate()
    const params = useParams()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const login = props.login
    const logout = props.logout
    return (
        <div className={classes.main}>
            <div className={classes.body}>
                <h1>Welcome! Steven.</h1>
                <input type="username" value={username} onChange={(e) => { setUsername(e.target.value) }} placeholder="User name" className="app-input-1" />
                <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} placeholder="Password" className="app-input-1" />
                <button className='app-btn-common-1' onClick={() => {
                    login(username, password)
                        .then(logginedOrErr => {
                            if (logginedOrErr === true) {
                                navigate(`/${params.lang}`)
                            }
                            else alert(logginedOrErr)
                        })
                }}>Login</button>
            </div>
        </div>
    )
}

export default LoginPage;