import React, { useState, useRef, useEffect } from 'react';
import { S3 } from "@aws-sdk/client-s3";
import * as s3Functions from './functions/aws-s3';
import './App.css';
import axios from 'axios';
import TheHome from './pages/TheHome/TheHome';
import GlobalStore from './contexts/GlobalStore'
import { BrowserRouter, Routes, Navigate, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import Portfolio from './pages/Portfolio/Portfolio';
import Bio from './pages/Bio/Bio';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import LoginPage from './pages/LoginPage/LoginPage';
import TheHeader from './components/TheHeader/TheHeader';
import useWindowDimensions from './Hooks/useWindowDimensions';
import * as utilities from './functions/utilities'
import Compress from 'compress.js'

function App() {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  // Local Configs
  const localConfigsRef = useRef()
  const [restServerAddr, setRestServerAddr] = useState('')

  // remote configs
  const publicConfigFromServerRef = useRef({
    date: null,
    s3BucketRegion: null,
    s3BucketName: null,
    s3PublicBaseUrl: null
  })
  const afterLoginFromServerRef = useRef({
    accessKeyId: null,
    secretKey: null,
    token: null
  })

  // For styling
  const [windowWidth, windowHeight, wideScreen] = useWindowDimensions();
  const [whiteHeader, setWhiteHeader] = useState(false);
  const [loggedin, setLoggedin] = useState(false);

  // Get all
  const [allMeta, setAllMeta] = useState(null)
  const [pinedMeta, setPinedMeta] = useState(null)
  const [publicMeta, setPublicMeta] = useState(null)
  const s3ClientRef = useRef()


  const getLocalConfigFromHostingPublicFolder = () => {
    return fetch('/react-config.json')
      .then(res => {
        return res.json().then(json => {
          localConfigsRef.current = json
          return true
        })
      })
      .catch(e => {
        console.log(e);
        return false
      })
  }


  const getPublicConfigFromControlServer = () => {
    return axios.get(localConfigsRef.current.restServerAddr + "/api/spa-config")
      .then(function (response) {
        publicConfigFromServerRef.current = response.data
        return response
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  const updatePhotoMeta = () => {
    return axios.get(
      localConfigsRef.current.restServerAddr + "/api/photometa/all",
      {
        headers: {
          Authorization: 'Bearer ' + afterLoginFromServerRef.current.token
        }
      })
      .then(function (response) {
        setAllMeta(response.data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  const logout = () => {
    afterLoginFromServerRef.current = {
      accessKeyId: null,
      secretKey: null,
      token: null
    }
    s3ClientRef.current = null
    updatePhotoMeta()
    setLoggedin(false)
  }

  const login = (username, password) => {
    return axios.post(localConfigsRef.current.restServerAddr + "/api/login", { username: username, password: password })
      .then((response) => {
        afterLoginFromServerRef.current = response.data
        initS3Client(afterLoginFromServerRef.current.accessKeyId, afterLoginFromServerRef.current.secretKey)
        updatePhotoMeta()
        setLoggedin(true)
        return true
      })
      .catch(function (error) {
        logout()
        console.log(error);
        return error
      })

  }
  // init s3Client (Login)
  const initS3Client = (accessKeyId, secretKey) => {
    const creds = {
      accessKeyId: accessKeyId,
      secretAccessKey: secretKey,
    };
    s3ClientRef.current = new S3({
      apiVersion: '2006-03-01',
      region: publicConfigFromServerRef.current.s3BucketRegion,
      credentials: creds
    });

  }
  const s3GetByKey = (key) => {
    return s3Functions.s3GetByKey(s3ClientRef.current, publicConfigFromServerRef.current.s3BucketName, utilities.s3KeyInsert1920(key))
  }

  const s3Post = (key, file) => {
    const compress = new Compress()
    async function resizeImageFn(file) {
      const resizedImage = await compress.compress([file], {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        resize: true // defaults to true, set false if you do not want to resize the image width and height
      })
      const img = resizedImage[0];
      const base64str = img.data
      const imgExt = img.ext
      const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
      return resizedFiile;
    }
    return resizeImageFn(file)
    .then(resizedFile=>{
      return Promise.all([
        s3Functions.s3Post(s3ClientRef.current, publicConfigFromServerRef.current.s3BucketName, key, file),
        s3Functions.s3Post(s3ClientRef.current, publicConfigFromServerRef.current.s3BucketName, utilities.s3KeyInsert1920(key), resizedFile),
      ])
    })
  }

  const s3DeleteByKey = (key) => {
    return Promise.all([
      s3Functions.s3DeleteByKey(s3ClientRef.current, publicConfigFromServerRef.current.s3BucketName, key),
      s3Functions.s3DeleteByKey(s3ClientRef.current, publicConfigFromServerRef.current.s3BucketName, utilities.s3KeyInsert1920(key)),
    ])
  }

  const s3RenameByKey = (key, newKey) => {
    return Promise.all([
      s3Functions.s3RenameByKey(s3ClientRef.current, publicConfigFromServerRef.current.s3BucketName, key, newKey),
      s3Functions.s3RenameByKey(s3ClientRef.current, publicConfigFromServerRef.current.s3BucketName, utilities.s3KeyInsert1920(key), utilities.s3KeyInsert1920(newKey)),
    ])
  }

  useEffect(() => {
    getLocalConfigFromHostingPublicFolder()
      .then((res) => {
        if (res === true) {
          setRestServerAddr(localConfigsRef.current.restServerAddr)
          getPublicConfigFromControlServer()
            .then((res) => {
              updatePhotoMeta()
            })
          // Login
          // login("tonystevenj", "root")
        }
      })
  }, [])




  // Update public and pined meta
  useEffect(() => {
    if (!allMeta) return
    const newPublic = []
    const newPined = []
    for (let i = 0; i < allMeta.length; i++) {
      if (allMeta[i].s3Key.split('/')[0] === 'public') {
        newPublic.push(allMeta[i])
        if (utilities.ifHasTag(allMeta[i], 'pined')) {
          newPined.push(allMeta[i])
        }
      }
    }
    setPublicMeta(newPublic)
    setPinedMeta(newPined)
  }, [allMeta])


  useEffect(() => {
    const pathnames = location.pathname.split('/')
    const levelTwoPage = pathnames[2]
    if (levelTwoPage) {
      setWhiteHeader(false)
    }
    window.scrollTo(0, 0);
  }, [location])

  useEffect(() => {
    console.log("App.js updated");
    // console.log(publicConfigFromServerRef.current);
    // console.log(new Date(publicConfigFromServerRef.current.date).getFullYear());
    // console.log(windowWidth+" "+windowHeight+" "+wideScreen);
  })

  return (
    <GlobalStore.Provider value={{
      author: 'Steven Wang',
      allMeta: allMeta,
      updatePhotoMeta: updatePhotoMeta,
      s3GetByKey: s3GetByKey,
      s3Post: s3Post,
      s3DeleteByKey: s3DeleteByKey,
      s3RenameByKey: s3RenameByKey,
      restServerAddr: restServerAddr,
      publicConfigFromServerRef: publicConfigFromServerRef,
      afterLoginFromServerRef: afterLoginFromServerRef,
      wideScreen: wideScreen,
      loggedin: loggedin
    }}>
      <div className="App">
        <TheHeader whiteHeader={whiteHeader}></TheHeader>
        <Routes>
          <Route path="/" element={<TheHome pinedMeta={pinedMeta} setWhiteHeader={setWhiteHeader} />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/bio" element={<Bio />} />
          <Route path="/login" element={<LoginPage loggedin={loggedin} login={login} logout={logout} />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </div>
    </GlobalStore.Provider>
  );
}

export default App;