import { useEffect, useState } from 'react'


function useVisible(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        // callback function
        ([entry]) => {
            setIntersecting(entry.isIntersecting)
        },
        // options:
        {
            threshold: 0
        }
    )

    useEffect(() => {
        try{
            observer.observe(ref.current)
        }catch (e){
            console.log(e);
        }
        // Remove the observer as soon as the component is unmounted
        return () => { 
            observer.disconnect() 
        }
    // eslint-disable-next-line
    }, [])

    return isIntersecting
}

export default useVisible;

