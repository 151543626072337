import classes from './TitleCard.module.css'


function TitleCard(props) {
    const title = props.title
    const copy = props.copy
    return (
        <div className={classes.title}>
            <h1>{title}</h1>
            <p>{copy}</p>
        </div>
    )
}

export default TitleCard;