import classes from './TheHeader.module.css'
import React, { useEffect, useRef, useState, useContext, Fragment } from 'react';
import GlobalStore from '../../contexts/GlobalStore'
import { Link, useParams } from 'react-router-dom';



function TheHeader(props) {

    const store = useContext(GlobalStore)
    const wideScreen = store.wideScreen
    const params = useParams()
    const whiteHeader = props.whiteHeader
    const [showMobileNavBody, setShowMobileNavBody] = useState(false)

    return (
        <Fragment>
            {
                wideScreen ?
                    <div className={[classes.main, whiteHeader ? classes.white : null].join(' ')} >
                        <div className={classes.logo}>
                            <Link to={`/${params.lang}`} className={classes['btn-logo']}>Steven J.</Link>
                            <Link to={`/${params.lang}/login`} className={classes['btn-login']}>Login</Link>
                        </div>
                        <nav className={classes.nav}>
                            <ul>
                                <li><Link to={`/${params.lang}`}>HOME</Link></li>
                                <li><Link to={`/${params.lang}/portfolio`}>PORTFOLIO</Link></li>
                                <li><Link to={`/${params.lang}/bio`}>BIO</Link></li>
                            </ul>
                        </nav>
                    </div>
                    :
                    <div className={[classes['mobile-main'], whiteHeader ? classes.white : null, showMobileNavBody ? classes['mobile-active'] : null].join(' ')} >
                        <div className={classes['mobile-main-title']}>
                            <div className={classes.logo}><Link to={`/${params.lang}`}>Steven J.</Link></div>
                            <div className={[classes['mobile-toggle'],].join(' ')}
                                onClick={() => {
                                    setShowMobileNavBody(!showMobileNavBody)
                                }}>
                                <div className={classes['mobile-toggle-bar']}></div>
                            </div>
                        </div>
                        <div className={classes['mobile-main-body']}>
                            <nav>
                                <ul>
                                    <li onClick={() => { setShowMobileNavBody(!showMobileNavBody) }}><Link to={`/${params.lang}`}>HOME</Link></li>
                                    <li onClick={() => { setShowMobileNavBody(!showMobileNavBody) }}><Link to={`/${params.lang}/portfolio`}>PORTFOLIO</Link></li>
                                    <li onClick={() => { setShowMobileNavBody(!showMobileNavBody) }}><Link to={`/${params.lang}/bio`}>BIO</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
            }

        </Fragment>
    )
}

export default TheHeader;