import classes from './UploadWrapperBox.module.css'
import React, { useEffect, useRef, useState, useContext } from 'react';
import axios from 'axios';
import GlobalStore from '../../contexts/GlobalStore';
import PopupSimple from '../PopupSimple/PopupSimple';

function UploadWrapperBox(props) {
    const store = useContext(GlobalStore)
    const afterLoginFromServerRef = store.afterLoginFromServerRef
    const serverAddr = store.restServerAddr
    const updatePhotoMeta = store.updatePhotoMeta

    // for css
    const uploadElRef = useRef()
    const [showUploadControl, setShowUploadControl] = useState(false)
    const [fileUploading, setFileUploading] = useState(false)

    // for file upload
    const [fileUpload, setFileUpload] = useState(null)
    const [fakeSrcUpload, setFakeSrcUpload] = useState(null)

    // use "" rather than null as init, because js is complaining don't use null as <input> feild value
    const [fileNameUpload, setFileNameUpload] = useState("")
    const [fileExtensionUpload, setFileExtensionUpload] = useState("")
    const [filePathUpload, setFilePathUpload] = useState('public/')
    const [fileDateUpload, setFileDateUpload] = useState("")
    const [fileLocationUpload, setFileLocationUpload] = useState("")
    const [fileNoteUpload, setFileNoteUpload] = useState("")
    const [fileTagsUpload, setFileTagsUpload] = useState("")


    const initStates = () => {
        setFileUpload(null)
        setFakeSrcUpload(null)
        setFileNameUpload("")
        setFileExtensionUpload("")
        setFilePathUpload("public/")
        setFileDateUpload("")
        setFileLocationUpload("")
        setFileNoteUpload("")
        setFileTagsUpload("")
        uploadElRef.current.value = null
    }

    const uploadToRemote = () => {
        setFileUploading(true)
        const s3Key = filePathUpload + fileNameUpload + fileExtensionUpload
        const toPost = {
            name: fileNameUpload + fileExtensionUpload,
            date: fileDateUpload,
            location: fileLocationUpload,
            note: fileNoteUpload,
            tags: fileTagsUpload,
            s3Key: s3Key
        }
        // begin file upload
        return axios.post(serverAddr + "/api/photometa", toPost,
            {
                headers: {
                    Authorization: 'Bearer ' + afterLoginFromServerRef.current.token
                }
            })
            .then((response) => {
                console.log("Post to Studio server success!");
                // post image to S3 
                return store.s3Post(s3Key, fileUpload)
                    .then(() => {
                        console.log("Post to S3 success!");
                        setFileUploading(false)
                        return true
                    })
                    .catch((err) => {
                        console.log("Post to S3 failed");
                        console.log(err);
                        setFileUploading(false)
                        return false
                    })

            })
            .catch((error) => {
                console.log("Post to Studio server failed");
                console.log(error);
                setFileUploading(false)
                return false
            });
    }


    useEffect(() => {
        // init all set if user closed the upload body
        if (showUploadControl === false) {
            initStates()
        }

    }, [showUploadControl])
    return (
        <div className={classes.upload}>
            {
                // use label to style input type='file'
                // https://stackoverflow.com/questions/572768/styling-an-input-type-file-button
            }
            <label htmlFor="file-upload" className={classes['ui-wrapper']}>
                {props.children}
            </label>
            <input type="file"
                id="file-upload"
                ref={uploadElRef}
                style={{ display: "none" }}
                onChange={(event) => {
                    // Update the state
                    const uploadedFile = event.target.files[0]
                    // check point: if file is an image
                    // ...

                    // if user did select a file
                    if (uploadedFile) {
                        setFileUpload(uploadedFile)
                        const extensionIndex = uploadedFile.name.lastIndexOf('.')
                        setFileNameUpload(uploadedFile.name.slice(0, extensionIndex))
                        setFileExtensionUpload(uploadedFile.name.slice(extensionIndex, uploadedFile.name.length))
                        if (uploadedFile && uploadedFile.size) {
                            setFakeSrcUpload(URL.createObjectURL(uploadedFile))
                            setShowUploadControl(!showUploadControl)
                        } else {
                            alert("upload file error")
                        }
                    } else { // user hit cancel
                        // do nothing
                    }

                }} />
            {

                <PopupSimple show={showUploadControl} setShow={setShowUploadControl}>
                    <div className={classes['upload-body']}>
                        <div className={classes['upload-preview']}>
                            <img src={fakeSrcUpload} alt="" />
                        </div>
                        <div className={classes['upload-controls']}>
                            <p>File Name: </p>
                            <input
                                className='app-input-1'
                                value={fileNameUpload} onChange={(e) => {
                                    setFileNameUpload(e.target.value)
                                }} />
                            <p>S3 Key: </p>
                            <div className={classes['s3-key-input-group']}>
                                <div contentEditable
                                    data-text="S3 folder"
                                    className='app-input-1'
                                    onBlur={(e) => {
                                        setFilePathUpload(e.currentTarget.textContent)
                                    }}
                                    suppressContentEditableWarning={true}
                                >{filePathUpload}</div>
                                <p>{fileNameUpload + fileExtensionUpload}</p>
                            </div>
                            <input
                                placeholder='Date'
                                className='app-input-1'
                                value={fileDateUpload} onChange={(e) => {
                                    setFileDateUpload(e.target.value)
                                }} />
                            <input
                                placeholder='Location'
                                className='app-input-1'
                                value={fileLocationUpload} onChange={(e) => {
                                    setFileLocationUpload(e.target.value)
                                }} />
                            <input
                                placeholder='Note'
                                className='app-input-1'
                                value={fileNoteUpload} onChange={(e) => {
                                    setFileNoteUpload(e.target.value)
                                }} />
                            <input
                                placeholder='Tags'
                                className='app-input-1'
                                value={fileTagsUpload} onChange={(e) => {
                                    setFileTagsUpload(e.target.value)
                                }} />


                            <button
                                className='app-btn-common-1'
                                onClick={() => {
                                    uploadToRemote()
                                        .then((res) => {
                                            if (res === true) {
                                                updatePhotoMeta()
                                                setShowUploadControl(false)
                                            }
                                        })
                                }}>Upload</button>
                        </div>
                        {
                            fileUploading &&
                            <div className={classes.uploading}>
                                <img src='/assets/img/loading.gif' alt="" />
                            </div>
                        }
                    </div>

                </PopupSimple>
            }
        </div>
    )
}

export default UploadWrapperBox;