import ErrorPage from "../pages/ErrorPage/ErrorPage";

// Get a single image from Aws S3, and return the faked local URL from <img src=""/> usage.
export const s3GetByKey = (s3Client, bucket, s3Key) => {
    console.log("Get called");
    if (s3Client === null) {
        console.log("No s3-cleint object avaliable, please make sure connect to AWS S3 first.");
        return null
    }
    return s3Client.getObject({ Bucket: bucket, Key: s3Key })
        .then((data) => {
            console.log("S3: Get " + s3Key + " success!");
            const newres = new Response(data.Body)
            return newres.blob().then(blob => {
                const localUrl = URL.createObjectURL(blob)
                return localUrl
            })
        }
        ).catch((err) => {
            console.log("S3: Get " + s3Key + " failed: " + err);
            return null
        })
}

// Post a new photo to AWS S3
// bucket: the bucket name; s3Key: key (name) to store this file; file: the File object from <input type="file"/>
// note: if the s3Key already exsit, will overwrite, so added a check behavior first
export const s3Post = async (s3Client, bucket, s3Key, file) => {
    // Check if this file already exists
    const ifExist = await objectExist(s3Client, bucket, s3Key)
    if (ifExist) {
        alert("the name(key) already exist in s3 bucket, please choose aother name")
        return
    }
    // const fileExtension = s3Key.split('.')[s3Key.split('.').length-1]
    const params = {
        Body: file,
        Bucket: bucket,
        Key: s3Key,
        ContentType: 'image',
    }
    return s3Client.putObject(params)
}


// check if exist
export const objectExist = (s3Client, bucket, s3Key) => {
    return s3Client.headObject({
        Bucket: bucket,
        Key: s3Key,
    })
        .then((response) => {
            // key already exist, do nothing
            return true
        })
        .catch((err) => {
            return false
        })

}

export const s3DeleteByKey = (s3Client, bucket, s3Key) => {
    const params = {
        Bucket: bucket,
        Key: s3Key
    };
    return s3Client.deleteObject(params)
}

export const s3RenameByKey = (s3Client, bucket, s3Key, newKey) => {
    return s3Client.copyObject({
        Bucket: bucket,
        CopySource: `/${bucket}/${s3Key}`,
        Key: newKey
    })
        .then(() => {
            // Delete the old object
            return s3Client.deleteObject({
                Bucket: bucket,
                Key: s3Key
            })
        })
        // Error handling is left up to reader
        .catch((e) => console.error(e))
}
