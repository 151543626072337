export const ifHasTag = (photoMeta, tag) => {
    return photoMeta.tags.split(',').filter(e => e.trim() === tag).length > 0
}

export const s3KeyInsert1920 = (s3Key) => {
    const breakIndex = s3Key.lastIndexOf('/')
    const s3Key1920 = s3Key.substring(0, breakIndex) + '/1920' + s3Key.substring(breakIndex)
    return s3Key1920
}

export const s3KeyRemove1920 = (s3Key) => {
    return s3Key.split('/1920').join('')
}