import classes from './Carousel.module.css'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';

function Carousel(props) {
    const interval = props.interval

    const [offset, setOffset] = useState(0)
    const sceneElRef = useRef()
    const sceneWidth = sceneElRef.current ? sceneElRef.current.clientWidth : window.innerWidth
    const sceneUnit = "px"
    const singleDeg = 360 / props.children.length;

    // vw is the unit, so 100vw is the width, if 3: carouselZ = 50vw / tan(60deg)
    const carouselZ = Math.round(sceneWidth / 2 / Math.tan(singleDeg / 2 * Math.PI / 180));


    useEffect(() => {
        if (!interval) return
        const timer = window.setInterval(() => {
            setOffset(offset => offset - 1); // <-- Change this line!
        }, interval);
        return () => {
            window.clearInterval(timer);
        };
    }, []);
    console.log(carouselZ);
    return (
        <div className={classes.scene} ref={sceneElRef}>
            <div className={classes.carousel}
                style={{
                    transform: `translateZ(-${carouselZ}${sceneUnit})`
                }}
            >
                {
                    props.children.map((El, index) => {
                        return (
                            <div className={classes['carousel-cell']}
                                style={{
                                    transform: `rotateY(${(index + offset) * singleDeg}deg) translateZ(${carouselZ}${sceneUnit})`
                                }}
                            >
                                {El}
                            </div>
                        )
                    })
                }
                <div className={classes.holder}>
                    {
                        props.children && props.children[0] ? props.children[0] : props.children
                    }
                </div>

            </div>
            <div
                className={classes.before}
                onClick={() => {
                    setOffset(offset + 1)
                }}></div>

            <div
                className={classes.next}
                onClick={() => {
                    setOffset(offset - 1)
                }}></div>

        </div>
    )
}

export default Carousel;