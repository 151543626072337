import classes from './PopupSimple.module.css'
import React from 'react';

function PopupSimple(props) {
    const show = props.show
    const setShow = props.setShow;
    // const setShow = ()=>{};
    return (
        <div className={[classes.main, show ? null : classes['hiden']].join(' ')} >
            <div  onClick={()=>setShow(!show)} className={['app-x', classes.close].join(' ')}></div>
            {props.children}
        </div>
    )
}

export default PopupSimple;