import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import English from '../configs/languages/en.json'
import Chinese from '../configs/languages/zh.json'
import { useLocation } from 'react-router-dom'


const MultiLangWapper = (props) => {
    const location = useLocation()
    const [lang, setLang] = useState(English)
    useEffect(() => {
        const langId = parseLang(location.pathname)
        const newLang = parseLangId(langId)
        setLang(newLang)
    }, [location])
    const parseLang = (pathname) => {
        const pathes = pathname.split('/')
        return pathes[1]
    }

    const parseLangId = (langId) => {
        langId = langId + ''
        let newLang;
        if (langId === 'zh') {
            newLang = Chinese
        } else {
            // default or no match: English
            newLang = English
        }
        return newLang
    }


    return (
        <IntlProvider locale={navigator.language} messages={lang}>
            {/*
                messages: the texts you wanna use
                locale: for date format
            */}
            {props.children}
        </IntlProvider>
    )
}

export default MultiLangWapper;