import classes from './TheHome.module.css'
import React, { Fragment, useContext, useEffect, useRef } from 'react';
import GlobalStore from '../../contexts/GlobalStore';
import ScrollBox from '../../components/ScrollBox/ScrollBox';
import TheFooter from '../../components/TheFooter/TheFooter'
import Carousel from '../../components/Carousel/Carousel'
import useVisible from '../../Hooks/useVisible';
import { Link, useParams } from 'react-router-dom';
import TitleCard from '../../components/TitleCard/TitleCard';

function TheHome(props) {
    const store = useContext(GlobalStore)
    const wideScreen = store.wideScreen
    const publicConfigFromServerRef = store.publicConfigFromServerRef
    const year = new Date(publicConfigFromServerRef.current.date).getFullYear()
    const pinedMeta = props.pinedMeta
    const setWhiteHeader = props.setWhiteHeader
    const s3PublicBaseUrl = store.publicConfigFromServerRef.current.s3PublicBaseUrl
    const params = useParams()
    const coverRef = useRef()
    const coverVisible = useVisible(coverRef)

    const carouselImgs = [
        '/assets/img/cover/1.jpg',
        '/assets/img/cover/2.jpeg',
        '/assets/img/cover/3.jpeg',
    ]

    const mobileCarouselImgs = [
        '/assets/img/cover/m-1.jpeg',
        '/assets/img/cover/m-2.jpeg',
        '/assets/img/cover/m-3.jpeg',
    ]

    useEffect(() => {
        setWhiteHeader(coverVisible)
    }, [coverVisible])
    return (
        <div className={classes.main}>
            <ScrollBox allMeta={pinedMeta}>
                <Fragment>
                    <div className={classes.cover} ref={coverRef}>
                        {
                            wideScreen ?
                                <div className={[classes['cover-title']].join(' ')}>
                                    <h1>Steven J. Wang</h1>
                                    <p>Every moment is worth remembering.</p>
                                </div>
                                :
                                <div className={[classes['cover-title'], classes['cover-title-mobile']].join(' ')}>
                                    <h1>Steven J.<br />Wang</h1>
                                    <p>Every moment<br />is worth remembering.</p>
                                </div>
                        }

                        {
                            wideScreen ?
                                <Carousel interval={5000}>
                                    {
                                        carouselImgs.map(imgPath => {
                                            return (
                                                <img src={imgPath} alt="" />
                                            )
                                        })
                                    }
                                </Carousel>
                                :
                                <Carousel interval={5000}>
                                    {
                                        mobileCarouselImgs.map(imgPath => {
                                            return (
                                                <img src={imgPath} alt="" />
                                            )
                                        })
                                    }
                                </Carousel>

                        }




                        {/* <button className={classes.next}>Next</button>
                        <div className={classes.carousel}>
                            <div className={classes['carousel-cell']} src='/assets/img/cover/cover.jpg' alt="" />
                            <div className={classes['carousel-cell']} src='/assets/img/cover/cover.jpg' alt="" />
                            <div className={classes['carousel-cell']} src='/assets/img/cover/cover.jpg' alt="" />
                        </div> */}
                    </div>
                    <div className={classes.spacer} style={{ height: '8em' }}></div>
                    <TitleCard
                        title={'PINED ' + year}
                        copy={'Live in the light of photography, story.'}
                    ></TitleCard>
                    <div className={classes.spacer} style={{ height: '8em' }}></div>
                </Fragment>
                <Fragment>
                    <div className={'app-btn-anchor-1'} style={{ fontSize: "18px", marginBottom: "5em", marginTop: "3em" }}>
                        <Link to={`/${params.lang}/portfolio`}>View more impressive photos</Link>
                    </div>
                    <TheFooter></TheFooter>
                </Fragment>
            </ScrollBox>
        </div>
    )
}


export default TheHome;