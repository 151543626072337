import TheFooter from '../../components/TheFooter/TheFooter';
import classes from './Bio.module.css'
import React, { useEffect, useRef, useState, useContext } from 'react';
import GlobalStore from '../../contexts/GlobalStore';
import Popup from '../../components/Popup/Popup';
import TitleCard from '../../components/TitleCard/TitleCard';

function Bio(props) {
    const [showGfImg, setShowGfImg] = useState(false)
    //params
    const bioImgSrc = '/assets/img/bio.jpeg'
    const bioGfImgSrc = '/assets/img/bio_gf.jpeg'
    const copy = [
        {
            title: "About me",
            copy: "MS in computer science, graduated 2020 from Florida International University, a photographer & programmer. Love recording every beauty of the world. Love Miami. And Love my life"
        },
        {
            title: "Contact",
            copy: "If you fall in love with any one of my photos and want the original size, feel free to email me. I'm more than happy to share!"
        }
    ]

    const contacts = [
        {
            icon: '/assets/img/email.svg',
            href: 'mailto:tonystevenj@gmail.com',
            text: 'tonystevenj@gmail.com'
        },
        {
            icon: '/assets/img/facebook.svg',
            href: 'https://www.facebook.com/tonystevenj',
            text: 'https://www.facebook.com/tonystevenj'
        },
        {
            icon: '/assets/img/instagram.svg',
            href: 'https://www.instagram.com/tonystevenj',
            text: 'https://www.instagram.com/tonystevenj'
        },
        {
            icon: '/assets/img/github.svg',
            href: 'https://github.com/tonystevenj',
            text: 'https://github.com/tonystevenj'
        },
        {
            icon: '/assets/img/linkedin.svg',
            href: 'https://www.linkedin.com/in/tonystevenj',
            text: 'https://www.linkedin.com/in/tonystevenj'
        },
    ]

    const gf = [

        [
            ['0', '1', '0', '0', '0'],
            ['0', '1', '0', '0', '0'],
            ['0', '1', '0', '0', '0'],
            ['0', '1', '0', '0', '0'],
            ['0', '1', '1', '1', '1'],
        ],
        [
            ['0', '1', '1', '1', '0'],
            ['1', '0', '0', '0', '1'],
            ['1', '0', '0', '0', '1'],
            ['1', '0', '0', '0', '1'],
            ['0', '1', '1', '1', '0'],
        ],
        [
            ['1', '0', '0', '0', '1'],
            ['1', '0', '0', '0', '1'],
            ['0', '1', '0', '1', '0'],
            ['0', '1', '0', '1', '0'],
            ['0', '0', '1', '0', '0'],
        ],
        [
            ['0', '1', '1', '1', '1'],
            ['0', '1', '0', '0', '0'],
            ['0', '1', '1', '1', '1'],
            ['0', '1', '0', '0', '0'],
            ['0', '1', '1', '1', '2'],
        ],
    ]
    return (
        <div className={classes.main}>
            <div className={classes.spacer} style={{ height: "max(calc(100px + 6vw), 10vw, 8vh)" }}></div>
            <TitleCard
                title={'BIO'}
            ></TitleCard>
            <div className={classes.spacer} style={{ height: '4em' }}></div>

            <div className={classes.body}>
                <div className={classes['body-img']}>
                    <img src={bioImgSrc} alt="" />
                </div>
                <div className={classes['body-intro']}>
                    {
                        copy.map(copy => {
                            return (
                                <div key={copy.title} className={classes['body-intro-li']}>
                                    <h2>{copy.title}</h2>
                                    <p>{copy.copy}</p>
                                </div>
                            )
                        })
                    }
                    <div className={classes['body-intro-contacts']}>
                        {
                            contacts.map(contact => {
                                return (
                                    <a key={contact.text} href={contact.href} target="_blank">
                                        <img src={contact.icon} alt="" />
                                        <p>{contact.text}</p>
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>

            </div>
            <div className={classes.gf}>
                {
                    gf.map((letter, indexA) => {
                        return (
                            <div key={letter + indexA} className={classes.letter}>
                                {
                                    letter.map((row, index) => {
                                        return (
                                            <div key={row + index} className={classes.row}>
                                                {
                                                    row.map((px, index) => {
                                                        return (
                                                            <div
                                                                key={px + index}
                                                                className={[
                                                                    classes.px,
                                                                    indexA === gf.length - 1 ? classes['px-last'] : null,
                                                                    px === '1' ? classes['px-on'] : null,
                                                                    px === '2' ? classes['px-button'] : null,
                                                                    px === '2' && showGfImg ? classes['px-button-active'] : null
                                                                ].join(' ')}
                                                                onClick={() => {
                                                                    if (px === '2') {
                                                                        setShowGfImg(!showGfImg)
                                                                    }
                                                                }}
                                                            >

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
                <Popup show={showGfImg} setShow={setShowGfImg}>
                    <div className={classes['big-img']}>
                        <img src={bioGfImgSrc} alt="" />
                    </div>
                </Popup>
            </div>
            {/* <img src={bioGfImgSrc} /> */}
            <TheFooter></TheFooter>
        </div>
    )
}

export default Bio;