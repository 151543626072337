import React from 'react'


const GlobalStore = React.createContext({
    author: 'Steven Wang',
    s3GetByKey: null,
    s3Post: null,
    s3DeleteByKey: null,
    s3RenameByKey: null,
    restServerAddr: null,
    publicConfigFromServerRef: null,
    afterLoginFromServerRef: null,
    wideScreen: null,
    loggedin: null,
})

export default GlobalStore;