import React, { useEffect, useRef, useState, useContext } from 'react';
import classes from './Portfolio.module.css';
import axios from 'axios';
import GlobalStore from '../../contexts/GlobalStore';
import PhotoFrame from '../../components/PhotoFrame/PhotoFrame';
import TheFooter from '../../components/TheFooter/TheFooter';
import UploadWrapperBox from '../../components/UploadWrapperBox/UploadWrapperBox';
import TitleCard from '../../components/TitleCard/TitleCard';



function Portfolio(props) {
    const store = useContext(GlobalStore)
    const afterLoginFromServerRef = store.afterLoginFromServerRef
    const serverAddr = store.restServerAddr
    const allMeta = store.allMeta
    const loggedin = store.loggedin
    const updatePhotoMeta = store.updatePhotoMeta


    return (
        <div className={classes.main}>
            <div className={classes.spacer} style={{ height: "max(calc(100px + 6vw), 10vw, 8vh)" }}></div>
            <TitleCard
                title={'PORTFOLIO'}
                copy={'Let your moments shine through.'}
            ></TitleCard>
            <div className={classes.spacer} style={{ height: '4em' }}></div>
            <div className={[classes.gallery].join(' ')}>
                {
                    allMeta &&
                    allMeta.map((photoMeta) => {
                        return <PhotoFrame
                            key={photoMeta.s3Key}
                            photoMeta={photoMeta}
                            updatePhotoMeta={updatePhotoMeta}
                        ></PhotoFrame>
                    })
                }
                {
                    loggedin &&
                    <UploadWrapperBox>
                        <img src='/assets/img/img-upload.svg' alt="" className={classes['upload-btn']} />
                    </UploadWrapperBox>
                }
            </div>


            <TheFooter></TheFooter>
        </div>
    )
}

export default Portfolio;