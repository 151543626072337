import classes from './ScrollBox.module.css'
import React, { Fragment, useContext } from 'react';
import GlobalStore from '../../contexts/GlobalStore';
import * as utilities from '../../functions/utilities'

function ScrollBox(props) {
    const store = useContext(GlobalStore)
    const s3PublicBaseUrl = store.publicConfigFromServerRef.current.s3PublicBaseUrl
    const allMeta = props.allMeta

    return (
        <div className={classes.main}>
            {// Slot for Prefix
                props.children[0]
            }
            {
                allMeta &&
                allMeta.map((imgMeta, index) => {
                    return (
                        <Fragment key={imgMeta.s3Key}>
                            <div className={classes['img-container']}>
                                <img src={s3PublicBaseUrl + utilities.s3KeyInsert1920(imgMeta.s3Key)} alt='' />
                            </div>
                            <div className={classes['bml-wrapper-box']}>
                                <h2 className={classes['bml-wrapper-box-inner']}>{imgMeta.name.slice(0, imgMeta.name.lastIndexOf('.'))}</h2>
                            </div>
                            <div className={[classes.copy,index===allMeta.length-1? classes['copy-last']:null].join(' ')}>
                                <p>{imgMeta.note}</p>
                            </div>
                        </Fragment>
                    )
                })
            }
            <div className={classes['end-spacer']}></div>
            {// Slot for Surfix
                props.children[1]
            }

        </div>
    )
}

export default ScrollBox;