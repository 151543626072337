import classes from './Popup.module.css'
import React from 'react';

function Popup(props) {
    const show = props.show
    const setShow = props.setShow;
    // const setShow = ()=>{};
    return (
        <div className={[classes.main, show ? null : classes['hiden']].join(' ')} onClick={()=>setShow(!show)}>
            <div  onClick={()=>setShow(!show)} className={'app-x'} style={{position:'absolute', top:'clamp(20px, 5vw, 100px)', right:'clamp(20px, 5vw, 100px)'}}></div>
            {props.children}
        </div>
    )
}

export default Popup;