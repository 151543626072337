import { useState, useEffect } from 'react';

export default function useWindowDimensions() {
  const width = window.innerWidth
  const height = window.innerHeight
  const [windowDimensions, setWindowDimensions] = useState([width, height]);
  const [wideScreen, setwideScreen] = useState(width > height)

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth
      const height = window.innerHeight
      setWindowDimensions([width, height]);
      setwideScreen(width > height)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [...windowDimensions, wideScreen];
}
