import React, { useEffect, useRef, useState, useContext, Fragment } from 'react';
import classes from './PhotoFrame.module.css'
import GlobalStore from '../../contexts/GlobalStore';
import axios from 'axios';
import Popup from '../Popup/Popup';
import PopupSimple from '../PopupSimple/PopupSimple';
import * as utilities from '../../functions/utilities'

function PhotoFrame(props) {
    const store = useContext(GlobalStore)
    const afterLoginFromServerRef = store.afterLoginFromServerRef
    const publicConfigFromServerRef = store.publicConfigFromServerRef
    const serverAddr = store.restServerAddr
    const meta = props.photoMeta;
    const loggedin = store.loggedin
    const imgLoadingSrc = '/assets/img/img-loading.svg'
    const [imgUrl, setImgUrl] = useState(null)
    const [fileNameEdit, setFileNameEdit] = useState('')
    const [fileExtensionEdit, setFileExtensionEdit] = useState('')
    const [filePathEdit, setFilePathEdit] = useState('')
    const [fileDateEdit, setFileDateEdit] = useState('')
    const [fileLocationEdit, setFileLocationEdit] = useState('')
    const [fileNoteEdit, setFileNoteEdit] = useState('')
    const [fileTagsEdit, setFileTagsEdit] = useState('')

    // for css
    const [showEditor, setShowEditor] = useState(false)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [imgHovering, setImgHovering] = useState(false)
    const [showBigImg, setShowBigImg] = useState(false)
    const [imgFinishedLoading, setImgFinishedLoading] = useState(false)
    const imgElRef = useRef()

    const initMeta = () => {
        const extensionIndex = meta.name.lastIndexOf('.')
        setFileNameEdit(meta.name.slice(0, extensionIndex))
        setFileExtensionEdit(meta.name.slice(extensionIndex, meta.name.length))
        setFilePathEdit(meta.s3Key.slice(0, meta.s3Key.lastIndexOf('/') + 1))
        setFileDateEdit(meta.date)
        setFileLocationEdit(meta.location)
        setFileNoteEdit(meta.note)
        setFileTagsEdit(meta.tags)
    }

    const deletePhoto = () => {
        axios.delete(serverAddr + "/api/photometa/" + meta.id,
            {
                headers: {
                    Authorization: 'Bearer ' + afterLoginFromServerRef.current.token
                }
            })
            .then((data) => {
                console.log("Server delete success!");
                // console.log(data);
                // delete request to S3
                store.s3DeleteByKey(meta.s3Key)
                    .then((data) => {
                        console.log("S3:  Delete success!");
                        props.updatePhotoMeta()
                    })
                    .catch((err) => {
                        console.log("S3: delete error:" + err);
                    })
            }).catch((err) => {
                console.log(err);
            })
    }

    const postEdit = () => {
        const s3Key = filePathEdit + fileNameEdit + fileExtensionEdit
        const toPut = {
            id: meta.id,
            name: fileNameEdit + fileExtensionEdit,
            date: fileDateEdit,
            location: fileLocationEdit,
            note: fileNoteEdit,
            tags: fileTagsEdit,
            s3Key: s3Key
        }
        axios.put(serverAddr + "/api/photometa", toPut,
            {
                headers: {
                    Authorization: 'Bearer ' + afterLoginFromServerRef.current.token
                }
            })
            .then((response) => {
                console.log("Put to Studio server success!");
                // console.log(response);

                // object name change to S3 
                if (s3Key != meta.s3Key) {
                    store.s3RenameByKey(meta.s3Key, s3Key)
                        .then(() => {
                            props.updatePhotoMeta()

                        })
                } else {
                    props.updatePhotoMeta()
                }
            })
            .catch((error) => {
                console.log("Put to Studio server failed");
                console.log(error);
            });
    }

    useEffect(() => {
        initMeta()
        if (meta.s3Key.split('/')[0] === 'public') {
            setImgUrl(publicConfigFromServerRef.current.s3PublicBaseUrl + utilities.s3KeyInsert1920(meta.s3Key) )
        } else {
            store.s3GetByKey(meta.s3Key)
                .then((fakeUrl) => {
                    setImgUrl(fakeUrl)
                })
        }
    }, [])

    useEffect(() => {
        let mounted = true
        if (!imgUrl) return
        // mount img finish loading function
        imgElRef.current.decode()
            .then(() => {
                if (mounted && imgUrl)
                    setImgFinishedLoading(true)
            })
            .catch((e) => {
                console.log(e);
            })
        return () => {
            mounted = false
        }
    }, [imgUrl])
    return (
        <div className={classes['main']}>
            <div className={[classes['photo-wrapper']].join(' ')}
                onMouseEnter={() => { setImgHovering(true) }}
                onMouseLeave={() => { setImgHovering(false) }}
            >
                <img src={imgUrl} alt="" onClick={() => { setShowBigImg(!showBigImg) }}
                    className={imgFinishedLoading ? null : 'app-none'}
                    ref={imgElRef}
                />
                <img src={imgLoadingSrc} alt="loading"
                    className={[imgFinishedLoading ? 'app-none' : null, classes['loading-svg']].join(' ')}
                />
                {
                    // controlls 
                }
                <div className={[classes['photo-controll'], !loggedin || !imgHovering ? 'app-hidden' : null].join(' ')}>
                    <img src={'/assets/img/trash.svg'} alt="" onClick={() => { setShowDeleteConfirm(!showDeleteConfirm) }}></img>
                    <img src={'/assets/img/edit.svg'} alt="" onClick={() => {
                        setShowEditor(!showEditor)
                    }}></img>
                </div>
            </div>
            <Fragment>
                <PopupSimple show={showEditor} setShow={setShowEditor}>
                    <div className={classes['gallery-editor']} onClick={(e) => { e.stopPropagation() }}>
                        <p>File Name: </p>
                        <input
                            className='app-input-1'
                            value={fileNameEdit} onChange={(e) => {
                                setFileNameEdit(e.target.value)
                            }} />
                        <p>S3 Key: </p>
                        <div className={classes['s3-key-input-group']}>
                            <div contentEditable
                                data-text="S3 folder"
                                className='app-input-1'
                                onBlur={(e) => {
                                    setFilePathEdit(e.currentTarget.textContent)
                                }}
                                suppressContentEditableWarning={true}
                            >{filePathEdit}</div>
                            <p>{fileNameEdit + fileExtensionEdit}</p>
                        </div>
                        <input
                            placeholder='Date'
                            className='app-input-1'
                            value={fileDateEdit} onChange={(e) => {
                                setFileDateEdit(e.target.value)
                            }} />
                        <input
                            placeholder='Location'
                            className='app-input-1'
                            value={fileLocationEdit} onChange={(e) => {
                                setFileLocationEdit(e.target.value)
                            }} />
                        <input
                            placeholder='Note'
                            className='app-input-1'
                            value={fileNoteEdit} onChange={(e) => {
                                setFileNoteEdit(e.target.value)
                            }} />
                        <input
                            placeholder='Tags'
                            className='app-input-1'
                            value={fileTagsEdit} onChange={(e) => {
                                setFileTagsEdit(e.target.value)
                            }} />

                        <button
                            className='app-btn-common-1'
                            onClick={() => {
                                postEdit()
                                setShowEditor(false)
                            }}>Apply</button>

                    </div>
                </PopupSimple>
                <PopupSimple show={showDeleteConfirm} setShow={setShowDeleteConfirm}>
                    <div className={classes.delete}>
                        <h2>Comfirm delete this image?</h2>
                        <p>{meta.s3Key}</p>
                        <div className={classes['delete-btns']}>
                            <button
                                className='app-btn-common-1'
                                onClick={() => {
                                    deletePhoto()
                                    setShowDeleteConfirm(!showDeleteConfirm)
                                }}>Yes</button>
                            <button
                                className='app-btn-common-1'
                                onClick={() => { setShowDeleteConfirm(!showDeleteConfirm) }}>No</button>
                        </div>

                    </div>
                </PopupSimple>
                <Popup show={showBigImg} setShow={setShowBigImg}>
                    <div className={classes['big-img']}>
                        <img src={imgUrl} alt="" />
                    </div>
                </Popup>
            </Fragment>

        </div>
    )
}

export default PhotoFrame;